import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import ChallengeCard from "./ChallengeCard/ChallengeCard";
import "./Challenges.scss";
import { Calendar, Location } from "../../assets/icons/svg";
import { useLanguage } from "../../LanguageProvider";

const Challenges = () => {
  const { languageData } = useLanguage();
  return (
    <div className="challenge">
      <div className="challenge__container">
        <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
          <h2 className="challenge__container-title">
            {languageData.challenge.title}
          </h2>
        </ScrollAnimation>
        <div className="challenge__container-info">
          <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
            <div className="challenge__container-calendar">
              <div className="challenge__container-calendar-icon">
                <Calendar />
              </div>
              <p>{languageData.challenge.register}</p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
            <div className="challenge__container-location">
              <div className="challenge__container-location-icon">
                <Location />
              </div>
              <p>{languageData.challenge.start}</p>
            </div>
          </ScrollAnimation>
        </div>
        <div className="challenge__wrapp">
          <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
            <div className="challenge__single">
              <ChallengeCard
                price="€30"
                type={languageData.challenge.typeSingle}
                person={languageData.challenge.personSingle}
              />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
            <div className="challenge__team">
              <ChallengeCard
                price="€50"
                type={languageData.challenge.typeTeam}
                person={languageData.challenge.personTeam}
              />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" duration={3} animateOnce={true}>
            <div className="challenge__mountain">
              <ChallengeCard
                price="€25"
                type={languageData.challenge.typeMountain}
                person={languageData.challenge.personMount}
              />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
            <div className="challenge__run">
              <ChallengeCard
                price="€25"
                type={languageData.challenge.typeBike}
                person={languageData.challenge.personBike}
              />
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default Challenges;
